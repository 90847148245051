<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                        <route-breadcrumb></route-breadcrumb>
                    </nav>
                </div>
                <div class="col-lg-6 col-5 text-right">

                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="row">
                <div class="col">
                    <card no-body>
                        <template slot="header">
                            <div class="row">
                                <div class="col-6">
                                    <h3 class="mb-0">{{$t('message.offer')}}</h3>
                                    {{$t('message.totalPrice')}} {{ totalPrice | currency }} <sup>*</sup><br>
                                </div>
                                <div class="col-6 text-right">
                                    <!-- <base-split-button size="sm" type="primary" @primary-click="report(true, currentUser.lang)"
                                      :text="$t('message.export')" class="pr-1"
                                      v-if="currentUserRights.includes('Angebot_Exportieren')">
                                      <a class="dropdown-item" @click="report(false)">{{$t('message.without_prices')}}</a>
                                      <a class="dropdown-item" @click="report(true, 'en')">{{$t('message.english')}}</a>
                                      <a class="dropdown-item" @click="report(false, 'en')">{{$t('message.english_without_prices')}}</a>
                                    </base-split-button> -->

                                    <base-button size="sm" type="primary" @click="openExportModal" v-if="items.length > 0 && currentUserRights.includes('Angebot_Exportieren')">
                                      {{ $t('message.export') }}
                                    </base-button>

                                    <base-button v-if="sel.length > 0 && currentUserRights.includes('project.angebot.cancreatenavision')" size="sm" type="primary" @click="updateOrder">
                                        {{$t('message.updateOrder')}}
                                    </base-button>

                                    <base-button v-if="sel.length>0 && quoteOfSelected()=='none' && currentUserRights.includes('project.angebot.cancreatenavision')" size="sm" type="primary" @click="createQuote">
                                        {{$t('message.createQuote')}}
                                    </base-button>

                                    <base-button v-if="sel.length>0 && quoteOfSelected()=='one'" size="sm" type="primary" @click="updateQuote">
                                        {{$t('message.updateQuote')}}
                                    </base-button>

                                    <base-button size="sm" type="primary" v-if="sel.length>0 && currentUserRights.includes('bhb.status.can-modify-wirtschaftliche-freigabe')" @click="freigabe">
                                        {{$t('message.economic_release')}}
                                    </base-button>
                                </div>
                            </div>
                        </template>
                        <div class="row">
                          <div class="col m-3">
                            <el-select
                              class="select-primary mr-2"
                              v-model="areaFilterValue" filterable
                              :placeholder="$t('message.select_area')">
                                <el-option class="select-primary ml-1"
                                  value="$"
                                  :label="$t('message.all_areas')"
                                  key="-1"></el-option>
                                <el-option v-for="option in options.filter(o => o.type=='AREA')"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.name">
                                </el-option>
                              </el-select>
                              <el-select
                                class="select-primary mr-2"
                                v-model="positionFilterValue" filterable
                                :placeholder="$t('message.select_position')">
                                <el-option class="select-primary ml-1"
                                  value="$"
                                  :label="$t('message.all_positions')"
                                  key="-1"></el-option>
                                <el-option v-for="option in options.filter(o => o.type=='BHBPOS')"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.name">
                                </el-option>
                              </el-select>

                              <el-select
                                class="select-primary mr-2"
                                v-model="statusFilterValue"
                                placeholder="Status">

                              <el-option v-for="(option, index) in stateFilterValues"
                                      class="select-primary ml-1"
                                      :value="index"
                                      :label="option"
                                      :key="index"
                                      :disabled="(index === 4 || index === 6) && !currentUserRights.includes('bhb.status.can-modify-handlung-pl')">
                            </el-option>
                            </el-select>

                              <el-select
                                class="select-primary mr-2"
                                v-model="orderFilterValue"
                                placeholder="Auftrag">
                                <el-option :value="null" :key="-1" :label="$t('message.all')" />
                                <el-option v-for="(order, index) in project_orders"
                                        class="select-primary ml-1"
                                        :value="order.order_no"
                                        :label="order.order_no"
                                        :key="index">
                                </el-option>
                              </el-select>

                          </div>
                        </div>
                        <template slot="footer">
                            <div class="row">
                                <div class="col-12 text-right">
                                  <sup>*</sup>{{$t('message.offerNotification')}}
                                </div>
                            </div>
                        </template>
                        <vue-good-table
                            :columns="columns"
                            :rows="groupedByAreaItems"
                            :line-numbers="false"
                            :fixed-header="true"
                            max-height="1000px"
                            :select-options="{
                              enabled: project.state !== 'ARCHIVED',
                              disableSelectInfo: true
                            }"
                            :groupOptions="{
                              enabled: true
                            }"
                            @on-selected-rows-change="handleSelectionChange">
                             <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'sort'" style="width:200px;display:block;">
                                  <el-tooltip placement="right">
                                    <div slot="content">
                                      <img v-if="imageUrl(props.row, 'designs')" :src="imageUrl(props.row, 'designs')" style="width: 400px;"/>
                                    </div>
                                    <img v-if="imageUrl(props.row, 'annotation')" :src="imageUrl(props.row, 'annotation')" style="width: 100%"/>
                                  </el-tooltip>
                                </span>
                                <span v-else-if="props.column.field === 'key'">
                                  <router-link :to="{ path: `/project/${$route.params.id}/bhb/${props.row.id}`}" target= "_blank" v-if="currentUserRights.includes('Dateneingabe_Ansicht')">{{props.row.key}}</router-link>
                                  <span v-else>{{props.row.key}}</span>
                                </span>
                                <span v-else-if="(props.column.field === 'mat')">
                                  {{  props.row |  firstmaterial('MAT', currentLocale) }}
                                  <br />
                                  {{  props.row |  firstmaterial('KON', currentLocale) }}
                                  <br />
                                  {{  getPlaceFromMat(props.row) }}

                                </span>
                                <span v-else-if="props.column.field === 'quantity'">
                                  <div v-for="(mat, i) in props.row.materials" :key="i">
                                    <div v-if="mat.quantity !== '0.00'">{{mat.quantity}}</div>
                                  </div>
                                </span>
                                 <span v-else-if="props.column.field === 'unit'">
                                   <div v-for="(mat, i) in props.row.materials" :key="i">
                                    <div v-if="mat.unit">{{mat.unit}}</div>
                                  </div>
                                </span>
                                 <span v-else-if="props.column.field === 'width'">
                                  <div v-for="(mat, i) in props.row.materials" :key="i">
                                    {{mat.width | number}}
                                  </div>
                                </span>
                                 <span v-else-if="props.column.field === 'height'">
                                   <div v-for="(mat, i) in props.row.materials" :key="i">
                                     {{mat.height | number}}
                                  </div>
                                </span>
                                 <span v-else-if="props.column.field === 'price-unit'">
                                   <div v-for="(mat, i) in props.row.materials" :key="i">
                                    <span v-if="isCustomerAdr(currentUser, project, props.row.address)">
                                      {{mat.price_unit | currency}}
                                    </span>
                                  </div>
                                </span>
                                 <span v-else-if="props.column.field === 'price-total'">
                                    <span v-if="isCustomerAdr(currentUser, project, props.row.address)">
                                      <span v-if="props.row.price_percent > 0"> {{ props.row.price_percent }} %<br /> {{calculateValue(props.row) | currency}}</span>
                                      <span v-else>{{ props.row.price_total | currency }}</span>
                                    </span>
                                </span>
                                <span v-else-if="props.column.field === 'note'">
                                  <el-tooltip placement="top" v-if="props.row.bhb_note">
                                    <div slot="content">
                                      <div style="width: 150px;">
                                        {{props.row.bhb_note}}
                                      </div>
                                    </div>
                                    <span class="btn-inner--icon" v-if="props.row.bhb_note">
                                      <i class="fas fa-info text-black"></i>
                                    </span>
                                  </el-tooltip>
                                </span>
                                <span v-else-if="props.column.field === 'state_bhb'">
                                  <bhb-status-badge :state="props.row.state_bhb"></bhb-status-badge>
                                </span>
                                <span v-else-if="props.column.field === 'rel_business'">
                                  <i v-if="props.row.rel_business" class="el-icon-check"></i>
                                </span>
                                <span v-else-if="props.column.field === 'actions'">
                                   <el-tooltip :content="'Bearbeiten'" placement="top" v-if="currentUserRights.includes('Dateneingabe_Ansicht')">
                                        <base-button type="link" size="sm" @click="edit(props.row)" :disabled="project.state === 'ARCHIVED'">
                                            <span class="btn-inner--icon">
                                              <i class="fas fa-edit text-black"></i>
                                            </span>
                                        </base-button>
                                    </el-tooltip>
                                </span>
                              </template>
                            </vue-good-table>
                    </card>
                </div>
              </div>
        </div>

        <order-form
          :project="project"
          documentType="Quote"
          :show.sync="showNewQuote"
          @changed="onNewQuote"
        />
      <offer-export-form
        v-if="exportModal"
        :show.sync="exportModal"
        :items="sel"
      />
    </div>
</template>
<script>
  // Charts
  import { mapGetters, mapActions } from 'vuex'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import { Sortable } from 'sortablejs'
  import { setTimeout } from 'timers';
  import download from '@/util/download'
  import OrderForm from "@/views/Admin/Projects/Components/OrderForm";
  import OfferExportForm from './OfferExportForm';
  import groupBy from '@/util/groupBy'
  import BhbStatusBadge from '@/components/BHBStatusBadge'
  import isCustomerAdr from '@/util/customerAdr'

  export default {
    name: 'Offer',
    components: {
      RouteBreadCrumb,
      OrderForm,
      OfferExportForm,
      BhbStatusBadge
    },
    computed: {
      ...mapGetters([
        'project',
        'project_orders',
        'positions',
        'projectAddressPositions',
        'currentProjectAddress',
        'currentUserRights',
        'bhbStateValues',
        'currentUser',
        'currentLocale',
        'options',
        'docs',
        'offerPositions',
        'authorisedStateValues',
      ]),
      stateFilterValues () {
        const temp = [...new Set(this.authorisedStateValues)]
        temp.unshift('ALLE')
        return temp.map(i => this.$t('message.bhbState.' + i))
      },
      tempPositions: {
        get() {
          return Object.assign(this.offerPositions)
        },
        set (val) {
        }
      },
      totalPrice: function() {
        let total_price = 0

        Object.keys(this.percent_values)
          .filter(key => {
            total_price += this.percent_values[key]['sum']
            if(this.percent_values[key]['sum_percent']) {
              total_price += this.percent_values[key]['sum_percent']
            }
          })
        
        return total_price;
        
        /*
        const total = this.items.reduce((val, pos) => {
            const price = isCustomerAdr(this.currentUser, this.project, pos.address)
              ? Number.parseFloat(pos.price_total)
              : 0
            return val + price
          }, 0)

        const percent = this.positions.reduce((val, pos) => {
            let price = isCustomerAdr(this.currentUser, this.project, pos.address)
              ? Number(pos.price_percent)
              : 0;
            if (this.currentProjectAddress !== pos.address.address_id && this.currentProjectAddress !== 0) {
              price = 0;
            }
            return val + price;
          }, 0)
        return total * (1.0 + percent/100.0)
        */
      },
      items () {
        let result = this.offerPositions
          .filter(i => i.state_bhb !== 'ABGELEHNT')
          .map(i => {
            i.areaName = i.area ? i.area.name : 'No area'
            return i
          })

        let grouped = this.groupByPlace(result);
        this.percent_values = this.percentByPlace(grouped);

        result = this.statusFilterValue === 0
            ? result
            : result.filter(p => p.state_bhb === this.bhbStateValues[this.statusFilterValue - 1])

        if (this.orderFilterValue) {
            result = result.filter(p => p.order_no === this.orderFilterValue)
        }

        if (this.positionFilterValue !== '$') {
          result = result.filter(i => i.bhb_position ? i.bhb_position.name.toLowerCase() === this.positionFilterValue.toLowerCase() : false)
        }

        if (this.areaFilterValue !== '$') {
          result = result.filter(i => i.area ? i.area.name.toLowerCase() === this.areaFilterValue.toLowerCase() : false)
        }

        return result
      },
      groupedByAreaItems () {
        const grouped = groupBy(this.items, 'areaName')
        return Object.keys(grouped).sort().map(key => {
          const children = grouped[key]

          return {
            mode: 'span', // span means this header will span all columns
            label: key, // this is the label that'll be used for the header
            html: false, // if this is true, label will be rendered as html
            children
          }
        })
      },
      // items: function() {
      //   return this.projectAddressPositions.filter(pos => {
      //     switch (this.filter.mode) {
      //       case 'all': return true;
      //       case 'release':
      //         if (this.filter.text=='Inhaltlich') return pos.rel_content;
      //         if (this.filter.text=='Wirtschaftlich') return pos.rel_business;
      //         if (this.filter.text=='Beide') return pos.rel_content && pos.rel_business;
      //         break;
      //       case 'area':
      //         if (pos.area) {
      //           return pos.area.name == this.filter.text
      //         } else {
      //           return this.filter.text == null
      //         }

      //     }
      //     return true;
      //   })
      // },
      areas: function() {
        return new Set(this.offerPositions.map(pos => pos.area? pos.area.name : null))
      }
    },
    data() {
      return {
        // project: {},
        enableSorting: true,
        sel: [],
        changed: [],
        filter: {mode:'all', text:'Alle'},
        areaFilterValue: '$',
        positionFilterValue: '$',
        statusFilterValue: 0,
        orderFilterValue: null,
        showNewQuote: false,
        exportModal: false,
        offers: [],
        percent_values: [],
        columns: [    {
          label: '',
          field: 'sort',
          sortable: false,
          width: '200px',
          tdClass: 'minwidth200',
          thClass: 'minwidth200'
        },
        {
          label: this.$t('message.id'),
          field: 'key',
          sortable: false,
          tdClass: 'minwidth50',
          thClass: 'minwidth50'
        },
        {
          label: this.$t('message.positionBHB'),
          field: 'bhb_position.name',
          sortable: false,
          tdClass: 'minwidth80',
          thClass: 'minwidth80'
        },
        {
          label: this.$t('message.material'),
          field: 'mat',
          sortable: false,
          tdClass: 'minwidth80',
          thClass: 'minwidth80'
        },
        {
          label: this.$t('message.unit'),
          field: 'unit',
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('message.width'),
          field: 'width',
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('message.height'),
          field: 'height',
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('position.price_unit') ,
          field: 'price-unit',
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('message.price_total'),
          field: 'price-total',
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('message.notice'),
          field: 'note',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: this.$t('message.state'),
          field: 'state_bhb',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: this.$t('message.economic_release'),
          field: 'rel_business',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: this.$t('message.edit'),
          field: 'actions',
          tdClass: 'text-center',
          sortable: false,
        }]
      }
    },
    watch: {
      positions (val) {
        if (val !== null) {
          setTimeout(() => {

          }, 5000)
        }
      }
    },
    methods: {
      ...mapActions([
        'bulkWirtschaftlicheFreigabe',
        'bulkQuoteNo'
      ]),
      openExportModal() {
        this.exportModal = true;
      },
      edit (row) {
        this.$router.push({ path: `/project/${this.$route.params.id}/data/${row.id}`})
      },
      addItem () {
        this.$router.push({ path: `/project/${this.$route.params.id}/data/add`})
      },
      copySelection () {

      },
      deleteSelection () {

      },
      handleSelectionChange({ selectedRows }) {
        this.sel = selectedRows
      },
      freigabe() {
        this.bulkWirtschaftlicheFreigabe(this.sel.map(pos => pos.id))
      },
      groupByPlace(items) {
        let placeObject = {}

        items.filter(i => {
          if(i.address.address) {
            let key = i.address.address.name
            if (!placeObject[key]) {
              placeObject[key] = []
              placeObject[key]['sum'] = 0
              placeObject[key]['percent'] = 0
            }
            placeObject[key].push(i)
            placeObject[key]['sum'] += i.materials.reduce((val, mat) => {
              const price = mat.price_total === null ? 0 : Number.parseFloat(mat.price_total)
              if(mat.price_percent && mat.VariantCode == 'HANDL003') {
                placeObject[key]['percent'] = Number.parseFloat(mat.price_percent)
              }
              return val + price;
            }, 0)
          }
        })

        return placeObject;
      },
      percentByPlace(places) {
        for(const p in places) {
          if (places[p]['percent'] > 0) {
            console.log('ein p mit %', p)
            places[p]['sum_percent'] = Math.round(places[p]['sum'] * (places[p]['percent'] / 100) * 100) / 100;
          }
        }
        
        return places;
      },
      async apiCall(call) {
        try {
          const message = await call()
          this.$notify({
            verticalAlign: "top",
            horizontalAlign: "right",
            message: message,
            type: "success"
          });
        } catch (error) {
          console.error(error)
          this.$notify({
            verticalAlign: "top",
            horizontalAlign: "right",
            message: error.body ? error.body.message : error,
            type: "error",
            timeout: 0,
          });
        }
      },
      async updateOrder() {
        const self = this
        await this.apiCall(async () => {
          const data = {pos: self.sel.map(p => p.id), documentType: 'Order'}
          const orderNo = this.sel.map(pos => pos.order_no)[0]

          let {message} = await self.$http.put('dyn/order/'+orderNo, data)
          if (!message) message = self.$t('message.updateOrderOk')
          return message
        })
      },
      imageUrl(item, path, index=0) {
          if (item.documents) {
            const found = item.documents
              .filter(doc => doc.path === path)
              .reverse()
            if (found.length>0) {
              return found[0].url
            }
          }
          return '/app/img/empty.png';
      },
      quoteOfSelected() {
        const quoteNos = _.uniq(this.sel.map(pos => pos.quote_no))
        if (quoteNos.length!=1) return 'multiple'
        if (quoteNos[0]==null) return 'none'
        return 'one'
      },
      async createQuote() {
        if (this.quoteOfSelected()!='none') return
        this.showNewQuote = true
      },
      async onNewQuote(document) {
        const self = this
        await this.apiCall(async () => {
          await self.$http.post('offer', document)
          const ids = self.sel.map(p => p.id)
          await self.bulkQuoteNo({ posIds: ids, quoteNo: document.No})
          const data = {pos: ids, documentType: 'Quote'}
          let {message} = await self.$http.put('dyn/order/'+document.No, data)
          if (!message) message = self.$t('message.updateQuoteOk')
          return message
        })

      },
      async updateQuote() {
        await this.apiCall(async () => {
          const quoteNo = this.sel[0].quote_no
          const data = {pos: this.sel.map(p => p.id), documentType: 'Quote'}
          let {message} = await this.$http.put('dyn/order/'+quoteNo, data)
          if (!message) message = this.$t('message.updateQuoteOk')
          return message
        })
      },
      isCustomerAdr,
      calculateValue (row) {
        let total_percent = 0
        Object.keys(this.percent_values)
          .filter(key => {
            if(key == row.address.address.name) {
              total_percent = this.percent_values[key]['sum_percent']
            }
          })
        
        return total_percent;
        
        /*
        const total = this.items
          .reduce((val, pos) => {
            const price = isCustomerAdr(this.currentUser, this.project, pos.address)
              ? Number.parseFloat(pos.price_total)
              : 0
            return val + price
          }, 0)
        const percent = Number.parseFloat(row.price_percent)

        const value = total * (percent / 100)

        return value
        */
      },
      getPlaceFromMat(pos) {
        let address = ''
        pos.materials.filter(m => {
          if(m.type == 'KON' && m.VariantCode == 'HANDL003') {
            address = pos.address.address.name
          }
        })

        if(address == '') return null;
        
        return '(' + address + ')'
      }
    },
    mounted() {
      // const table = document.querySelector('.position-table tbody');
      // const self = this;
      // this.$refs.inputTable.sort('Area', 'ASC');
    },
  };
</script>
<style>

.minwidth200 > span {
  min-width: 200px !important;
  display: block;
}
.minwidth80 > span {
  min-width: 80px !important;
  display: block;
}
.minwidth50 > span {
  min-width: 50px !important;
  display: block;
}
.minwidth20 > span {
  min-width: 20px !important;
  display: block;
}
.vgt-checkbox-col {
  min-width: 20px !important;
}
</style>
